.w15pf47b{position:relative;margin:0;background-image:linear-gradient(rgba(0,0,0,0.827),rgba(0,0,0,0.8)), url(/contact_hero/contact_background.svg);background-position:center center;background-attachment:fixed;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover;background-size:cover;background-repeat:no-repeat;padding-bottom:32px;}.w15pf47b .md{display:block;padding:0 67px;}.w15pf47b .sm{display:none;}@media (max-width:1200px){.w15pf47b{padding-bottom:26px;}.w15pf47b .md{display:none;}.w15pf47b .sm{display:block;}}
.chdpqq1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:24px;margin:0 96px;background-repeat:no-repeat;height:100%;background-size:fill;}@media (max-width:1200px){.chdpqq1{margin:0 24px;gap:unset;}}
.trmftnu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;text-align:center;margin-top:64px;gap:8px;color:#F5F5F5;}.trmftnu h3{-webkit-letter-spacing:2px;-moz-letter-spacing:2px;-ms-letter-spacing:2px;letter-spacing:2px;color:#F5F5F5;margin:0;font-size:30px;font-style:normal;font-weight:700;line-height:140%;padding-top:4px;}.trmftnu p{font-size:18px;line-height:36px;font-weight:500;padding:0;margin:0;}@media (max-width:1200px){.trmftnu h3{font-size:22px;line-height:33px;}.trmftnu p{font-size:16px;line-height:25.6px;}}
.b1h1ee72{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;height:100%;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:18px;padding-top:38px;padding-bottom:16px;}@media (max-width:1200px){.b1h1ee72{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:20px;}}
.b1f1w8fx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;text-align:center;width:608px;min-height:323px;border-radius:6px;border:0px solid #1B1B1B;gap:8px;background-color:#FFFFFF;}@media (max-width:1200px){.b1f1w8fx{width:100%;background-color:#F5F5F5;}}
.t1hy9hkf{text-align:center;font-family:"Zen Kaku Gothic New";font-size:22px;font-style:normal;font-weight:700;line-height:140%;padding-top:4px;color:#1B1B1B;}@media (max-width:1200px){.t1hy9hkf{font-size:18px;line-height:25.2px;width:273px;padding-top:30px;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;}}
.bp9wv67{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:10px;padding:0 24px;padding-bottom:10px;text-align:center;}@media (max-width:1200px){.bp9wv67{gap:10px;-webkit-flex:1 0 100%;-ms-flex:1 0 100%;flex:1 0 100%;}}
.b1nxan3o{-webkit-flex:1 0 40%;-ms-flex:1 0 40%;flex:1 0 40%;box-sizing:border-box;border-radius:6px;background:#F5F5F5;}.b1nxan3o p{margin:unset;padding:12px 0;color:#1B1B1B;font-family:"Zen Kaku Gothic New";font-size:16px;font-style:normal;font-weight:500;line-height:22.4px;}@media (max-width:1200px){.b1nxan3o{-webkit-flex:1 0 100%;-ms-flex:1 0 100%;flex:1 0 100%;background:#FFFFFF;}}
.b182k3bk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (max-width:1200px){.b182k3bk{padding-bottom:32px;padding-top:14px;}}
.c926ez3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-top:12px;padding-bottom:26px;}.c926ez3 .ant-btn-primary{width:250;height:61px;font-size:18px;font-weight:bold;line-height:140%;background-color:#F95F00;border-color:#F95F00;border-radius:50px;}@media (max-width:1200px){.c926ez3 .ant-btn-primary{width:250px;height:48px;font-size:16px;line-height:140%;background:#F95F00;}}@media (max-width:768px){.c926ez3 .ant-btn-primary{height:48px;font-size:16px;line-height:140%;background:#F95F00;}}@media (max-width:545px){.c926ez3 .ant-btn-primary{font-size:14px;height:44px;width:324px;background:#F95F00;}}
.c1qv0u0u{color:var(--c1qv0u0u-0);}
