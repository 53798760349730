.w19n87sa{position:relative;margin:0;background-color:#F5F5F5;padding-bottom:50px;}@media (min-width:1350px){.w19n87sa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-bottom:64px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.w19n87sa div{max-width:1350px;padding-bottom:12px;}}
.ckmwz11{background-color:#FFFFFF;border-radius:10px;padding-bottom:36px;}@media (max-width:1350px){.ckmwz11{margin:0 64px;padding-bottom:16px;overflow:hidden;}}@media (max-width:768px){.ckmwz11{margin:0 24px;border-radius:6px;}}
.i1darwx1{padding-top:43px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;row-gap:6px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.i1darwx1 h1{font-family:Zen Kaku Gothic New;font-weight:700;-webkit-letter-spacing:2px;-moz-letter-spacing:2px;-ms-letter-spacing:2px;letter-spacing:2px;line-height:48px;font-size:30px;gap:16px;margin:0;}@media (max-width:768px){.i1darwx1{row-gap:0;margin-bottom:24px;padding-top:32px;}.i1darwx1 h1{font-size:18px;line-height:28.8px;}}
.cf7vuvr{color:#F95F00;}.cf7vuvr span{color:#1B1B1B;}@media (max-width:768px){.cf7vuvr{font-size:24px !important;line-height:36px !important;width:290px;text-align:center;}}
@media (max-width:768px){.hu9gaen{text-align:center;}}
.i1c1l1iw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;margin:0 54px;gap:31px;}.i1c1l1iw h2{font-size:24px;line-height:58px;font-weight:500;color:#1B1B1B;text-align:center;}.i1c1l1iw .arrow{width:26px;height:auto;}.i1c1l1iw .arrow > path{stroke:#121212 !important;}.i1c1l1iw .inverted{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);width:26px;height:auto;}@media (max-width:1350px){.i1c1l1iw{margin:0;gap:12px;}}@media (max-width:1200px){.i1c1l1iw{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}@media (max-width:865px){.i1c1l1iw{gap:24px;margin:0 24px;}.i1c1l1iw h2{font-size:16px;line-height:22.4px;font-weight:700;}.i1c1l1iw .arrow{width:unset;}}@media (max-width:1200px){.i1c1l1iw{gap:24px;margin:0;}.i1c1l1iw .arrow{-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);}.i1c1l1iw .inverted{-webkit-transform:rotate(270deg);-ms-transform:rotate(270deg);transform:rotate(270deg);}}@media (max-width:545px){.i1c1l1iw{gap:10px;padding-bottom:16px;}.i1c1l1iw .inverted{margin-top:30px;}}
.i14szuf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:31px;}.i14szuf img{width:328px;height:auto;}@media (max-width:865px){.i14szuf{gap:20px;}.i14szuf img{width:250px !important;}}@media (max-width:768px){.i14szuf img{width:188.33px !important;}}
.irkq9s0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.c1fg6kxg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:31px;margin-top:42px;}.c1fg6kxg p{width:272px;font-size:20px;line-height:34px;text-align:justify;max-width:272px;font-weight:500;}.c1fg6kxg .ant-btn-primary{width:100%;height:61px;font-size:18px;font-weight:bold;line-height:140%;background-color:#F95F00;border-color:#F95F00;border-radius:50px;}@media (max-width:865px){.c1fg6kxg{margin-top:0;gap:10px;}.c1fg6kxg .ant-btn-primary{height:48px;font-size:16px;line-height:140%;background:#F95F00;}.c1fg6kxg svg{width:163px;}}@media (max-width:545px){.c1fg6kxg p{font-size:16px;line-height:25.6px;text-align:justify;}.c1fg6kxg .ant-btn-primary{height:44px;font-size:16px;line-height:22.4px;margin-top:unset;background:#F95F00;}}
.i1lvxpqt{display:table;border-spacing:15px;}.i1lvxpqt .row{display:table-row;}.i1lvxpqt .cell{width:50px;height:50px;display:table-cell;}.i1lvxpqt img{width:96px;height:96px;object-fit:cover;border-radius:50%;}@media (max-width:545px){.i1lvxpqt{height:310px;border-spacing:10px;padding-bottom:16px;}.i1lvxpqt img{width:86px;height:86px;object-fit:cover;}}
