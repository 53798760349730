.wp7oivx{position:relative;margin:0;padding:0 96px;background-color:#F5F5F5;padding-bottom:64px;}@media (min-width:1200px){.wp7oivx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.wp7oivx div{max-width:1350px;}}@media (max-width:1200px){.wp7oivx{text-align:center;padding:0 64px;padding-bottom:64px;}}@media (max-width:768px){.wp7oivx{padding:0 24px;padding-bottom:40px;}}
.c1h7cpcr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:24px;margin:0 6px;padding-top:88px;}@media (min-width:1440px){.c1h7cpcr{margin:0 56px;}}@media (max-width:1200px){.c1h7cpcr{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}@media (max-width:768px){.c1h7cpcr{width:100%;margin:0;padding-top:48px;}}
.tl0icv8{text-align:left;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;}.tl0icv8 h3{font-size:28px;font-style:normal;font-weight:700;line-height:140%;padding:0;margin:0;}.tl0icv8 p{padding:0;margin:0;font-size:18px;font-style:normal;font-weight:500;line-height:34px;}@media (max-width:1200px){.tl0icv8{gap:8px;}}@media (max-width:768px){.tl0icv8 h3{font-size:22px;line-height:30.8px;}.tl0icv8 p{font-size:18px;line-height:34px;}}
.s11us7x1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:24px;}@media (max-width:1200px){.s11us7x1{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}
.su39l0l{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:24px;}.su39l0l img{mix-blend-mode:multiply;max-width:280px;max-height:262px;}@media (max-width:1200px){.su39l0l{gap:10px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.su39l0l img{-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;}}
.smzr1j9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}@media (max-width:865px){.smzr1j9{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}
.scsyivu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:red;}.scsyivu .arrow{width:20px;height:auto;position:absolute;top:35%;}.scsyivu .arrow > path{stroke:#F95F00 !important;}@media (max-width:1200px){.scsyivu{width:100%;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.scsyivu .arrow{position:absolute;rotate:90deg;top:unset;}}
.s1wyd26s{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0;}.s1wyd26s h4{text-align:center;font-size:18px;font-style:normal;font-weight:600;line-height:normal;margin-bottom:4px;color:#F85E00;}.s1wyd26s h3{text-align:center;font-size:20px;font-style:normal;font-weight:700;line-height:normal;margin-bottom:6px;}.s1wyd26s p{color:#2E2E2E;font-size:16px;font-style:normal;font-weight:500;line-height:28px;text-align:justify;}@media (max-width:768px){.s1wyd26s h4{font-size:16px;line-height:19.5px;}.s1wyd26s h3{font-size:20px;line-height:28.96px;margin-bottom:8px;}.s1wyd26s p{font-size:16px;line-height:28px;}.s1wyd26s img{margin-top:33px;}}
.pkjwgur{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;gap:39px;}@media (max-width:865px){.pkjwgur{gap:12px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}
.p1ds7ciy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0px;padding:20px 24px 18px 24px;border:1px solid #F5F5F5;border-radius:8px;background-color:#FFFFFF;width:100%;text-align:left;}.p1ds7ciy h4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;gap:12px;padding-bottom:8px;font-size:16px;font-style:normal;font-weight:600;line-height:normal;margin-bottom:0;color:#222222;}.p1ds7ciy p{font-size:16px;font-style:normal;font-weight:500;line-height:24px;padding:0;padding-bottom:4px;margin:0;text-align:justify;}.p1ds7ciy ul{list-style-type:none;padding:0;}.p1ds7ciy li{font-size:12px;font-style:normal;font-weight:500;line-height:20px;}@media (max-width:865px){.p1ds7ciy{width:100%;}}
.d1d9oe5n{border-left:2px solid #F5F5F5;}
.c869zln{color:var(--c869zln-0);}
